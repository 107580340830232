/**
    Since the module is not sandboxed inside the NPM package 
    the styles were overriden by the page CSS
    This implementation was done to revert to the actual designs.
**/
.kahani-cta-button-container button {
    width: 80% !important;
}

.kahani-icon-muted,
.kahani-icon-unmuted {
    left: 15px !important;
    padding: 0 !important;
    bottom: 55px !important;
}

.video-js .vjs-tech {
    height: 100% !important;
    position: absolute !important;
}